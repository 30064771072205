import type { ReactNode, JSX } from "react";
import { useState, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Box,
  ListItemAvatar,
  Avatar,
  Divider,
  Menu,
  MenuItem,
  Typography,
  Collapse,
} from "@mui/material";
import {
  Home as HomeIcon,
  TableChart as TableChartIcon,
  AddCircle as AddCircleIcon,
  Build as BuildIcon,
  BarChart as BarChartIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  List as ListIcon,
  MoreVert as MoreVertIcon,
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { useAuth } from "react-oidc-context";
import { authorisedRouteRoles, type Route, type Role } from "@/routes";
import { GET_PORT_QUERY } from "@/graphql";

interface NavBarProps {
  backButton?: boolean;
}

interface NavigationListItem {
  title: string;
  route: Route;
  icon: ReactNode;
  children?: NavigationListItem[];
}

export default function NavBar({ backButton }: NavBarProps): JSX.Element {
  const auth = useAuth();
  const userMoreButtonRef = useRef<HTMLButtonElement>(null);
  const [userMoreMenuOpen, setUserMoreMenuOpen] = useState(false);
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { data: portData } = useQuery(GET_PORT_QUERY, {
    skip: !auth.isAuthenticated,
  });
  const navigate = useNavigate();
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  function toggleExpanded(route: string): void {
    setExpandedItems((prev) =>
      prev.includes(route)
        ? prev.filter((item) => item !== route)
        : [...prev, route],
    );
  }

  const actionButton = backButton ? (
    <IconButton
      size="large"
      edge="start"
      aria-label="back"
      color="inherit"
      onClick={() => navigate(-1)}
    >
      <ArrowBackIcon />
    </IconButton>
  ) : (
    <IconButton
      size="large"
      edge="start"
      aria-label="menu"
      color="inherit"
      onClick={() => setDrawerOpen(true)}
    >
      <MenuIcon />
    </IconButton>
  );

  const renderNavigationItem = (
    item: NavigationListItem,
    depth: number = 0,
  ): JSX.Element | null => {
    if (
      !hasUserAccessToRoute(
        item.route,
        auth.user?.profile.realm_access.roles ?? [],
      )
    ) {
      return null;
    }

    const hasChildren = item.children && item.children.length > 0;
    const isExpanded = expandedItems.includes(item.route);
    const isSelected =
      location.pathname === item.route ||
      (hasChildren &&
        item.children?.some((child) =>
          location.pathname.startsWith(child.route),
        ));

    return (
      <Box key={item.route}>
        <ListItem disablePadding>
          <ListItemButton
            component={hasChildren ? "div" : Link}
            to={hasChildren ? undefined : item.route}
            onClick={hasChildren ? () => toggleExpanded(item.route) : undefined}
            selected={isSelected}
            sx={{ pl: depth * 2 + 2 }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
            {hasChildren &&
              (isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
          </ListItemButton>
        </ListItem>
        {hasChildren && (
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <List disablePadding>
              {item.children!.map((child) =>
                renderNavigationItem(child, depth + 1),
              )}
            </List>
          </Collapse>
        )}
      </Box>
    );
  };

  return (
    <>
      <AppBar position="sticky" color="primary" id="top_nav">
        <Toolbar>
          {auth.isAuthenticated && actionButton}
          <Typography variant="h6" marginLeft={2}>
            AssetSide
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box sx={{ width: 256 }}>
          <List>
            <ListItem
              secondaryAction={
                <IconButton
                  onClick={() => setUserMoreMenuOpen(true)}
                  ref={userMoreButtonRef}
                >
                  <MoreVertIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>{auth?.user?.profile.given_name?.[0]}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={auth?.user?.profile.name}
                secondary={portData?.port.name}
              />
            </ListItem>
            <Menu
              open={userMoreMenuOpen}
              anchorEl={userMoreButtonRef.current}
              onClose={() => setUserMoreMenuOpen(false)}
            >
              <MenuItem onClick={() => auth.signoutPopup()}>Sign out</MenuItem>
            </Menu>
          </List>
          <Divider />
          <List>
            {navigationListItems.map((item) => renderNavigationItem(item))}
          </List>
        </Box>
      </Drawer>
    </>
  );
}

const navigationListItems: NavigationListItem[] = [
  {
    title: "Home",
    route: "/home",
    icon: <HomeIcon />,
  },
  {
    title: "Assets Table",
    route: "/assets-table",
    icon: <TableChartIcon />,
  },
  {
    title: "Manage Assets",
    route: "/manage-assets",
    icon: <AddCircleIcon />,
  },
  {
    title: "Configuration",
    route: "/configuration",
    icon: <BuildIcon />,
    children: [
      {
        title: "Default Properties",
        route: "/configuration/default-properties",
        icon: <BuildIcon />,
      },
      {
        title: "Loads",
        route: "/configuration/loads",
        icon: <BuildIcon />,
      },
      {
        title: "Inspection Timeline",
        route: "/configuration/inspection-timeline",
        icon: <BuildIcon />,
      },
    ],
  },
  {
    title: "Metrics",
    route: "/metrics",
    icon: <BarChartIcon />,
  },
  {
    title: "Manage Access",
    route: "/manage-access",
    icon: <AdminPanelSettingsIcon />,
  },
  {
    title: "Batch Entry",
    route: "/batch-entry",
    icon: <ListIcon />,
  },
];

function hasUserAccessToRoute(route: Route, userRoles: Role[]): boolean {
  return userRoles.some((role) => authorisedRouteRoles[route].has(role));
}
