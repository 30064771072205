import NavBar from "../../components/NavBar";
import type { JSX } from "react";

export default function UnauthorizedPage(): JSX.Element {
  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row mt-3">
          <h1 className="text-center">
            You are unauthorized to access this page.
          </h1>
        </div>
      </div>
    </>
  );
}
