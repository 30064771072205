import { Navigate, useLocation } from "react-router-dom";
import { authorisedRouteRoles, type Route } from "@/routes";
import UnauthorizedPage from "@/pages/Unauthorized";
import { useAuth } from "react-oidc-context";
import type { JSX } from "react";

interface RequireAuthProps {
  children: JSX.Element;
}
/**
 * A component that requires the user to be authenticated.
 * Modified from https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
 */
export default function RequireAuth({
  children,
}: RequireAuthProps): JSX.Element | null {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.isAuthenticated) return <Navigate to="/login" />;
  if (!auth.user) return null;

  const userProfile = auth.user.profile;

  const isAuthorised = (() => {
    const authorisedRoles = authorisedRouteRoles[location.pathname as Route];

    const userRoles = userProfile.realm_access.roles;
    return [...authorisedRoles].some((role) => userRoles.includes(role));
  })();

  if (!isAuthorised) return <UnauthorizedPage />;
  return children;
}
