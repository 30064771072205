/**
 * @file Provides a mapping between a route and the roles that are allowed to access that route
 */

const ADMIN = "Admin";
const JUNIOR_ENGINEER = "Junior Engineer";
const SENIOR_ENGINEER = "Senior Engineer";
const CONTRACTOR = "Contractor";

export type Role =
  | "Admin"
  | "Junior Engineer"
  | "Senior Engineer"
  | "Contractor";

const homeRoles = new Set<Role>([
  ADMIN,
  CONTRACTOR,
  JUNIOR_ENGINEER,
  SENIOR_ENGINEER,
]);

export const authorisedRouteRoles: Record<Route, Set<Role>> = {
  "/manage-assets": new Set([ADMIN, JUNIOR_ENGINEER, SENIOR_ENGINEER]),
  "/home": homeRoles,
  "/": homeRoles,
  "/assets-table": new Set([
    ADMIN,
    SENIOR_ENGINEER,
    JUNIOR_ENGINEER,
    CONTRACTOR,
  ]),
  "/metrics": new Set([ADMIN, SENIOR_ENGINEER]),
  "/configuration": new Set([ADMIN, SENIOR_ENGINEER]),
  "/manage-access": new Set([ADMIN]),
  "/home/properties": homeRoles,
  "/home/inspections": homeRoles,
  "/home/notes": homeRoles,
  "/batch-entry": new Set([
    ADMIN,
    CONTRACTOR,
    JUNIOR_ENGINEER,
    SENIOR_ENGINEER,
  ]),
  "/configuration/default-properties": new Set([ADMIN, SENIOR_ENGINEER]),
  "/configuration/loads": new Set([ADMIN, SENIOR_ENGINEER]),
  "/configuration/inspection-timeline": new Set([ADMIN, SENIOR_ENGINEER]),
};

export type Route =
  | "/manage-assets"
  | "/home"
  | "/"
  | "/assets-table"
  | "/metrics"
  | "/configuration"
  | "/manage-access"
  | "/home/properties"
  | "/home/inspections"
  | "/home/notes"
  | "/batch-entry"
  | "/configuration/default-properties"
  | "/configuration/loads"
  | "/configuration/inspection-timeline";
